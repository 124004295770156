import { useContext, useState, useRef, Fragment } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'

import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { styled } from '@mui/system'

import { AuthenticationContext } from '../authentication/authenticationContext'
import { GroupContext } from '../groups/groupContext'
import UpgradeWidget from '../common/UpgradeWidget'
import UpgradeButton from '../common/UpgradeButton'
import TableChip from '../common/TableChip'

import { ReactComponent as NewIcon } from '../../icons/navIcons/newIcon.svg'
// import { ReactComponent as DashboardIcon } from '../../icons/navIcons/dashboardIcon.svg'
import { ReactComponent as TalksIcon } from '../../icons/navIcons/talksIcon.svg'
import { ReactComponent as CodesIcon } from '../../icons/navIcons/codesIcon.svg'
import { ReactComponent as LeadsIcon } from '../../icons/navIcons/leadsIcon.svg'
import { ReactComponent as ResourcesIcon } from '../../icons/navIcons/resourcesIcon.svg'
import { ReactComponent as CommunityIcon } from '../../icons/navIcons/communityIcon.svg'
import { ReactComponent as GiftIcon } from '../../icons/giftIcon.svg'
import { ReactComponent as GroupEventsIcon } from '../../icons/group-events.svg'
import { ReactComponent as SettingsIcon } from '../../icons/navIcons/settingsIcon.svg'
import { ReactComponent as ExternalLinkIcon } from '../../icons/externalLinkIcon_16x16.svg'
import { ReactComponent as HeadphoneIcon } from '../../icons/headphoneIcon.svg'
import {
  drawerWidth,
  drawerWidthCollapsed,
  drawerWidthMobile,
  upperNavHeight,
  upperNavHeightMobile,
} from '../../styles/layoutNavConstants'

import { usePermissionHelper } from '../../utils/permission_helper'
import { useIsChapterPage } from '../../utils/pathHelper'

const StyledGiftIcon = styled(GiftIcon)(({ theme }) => ({
  stroke: theme.palette.neutral.dark,
  width: '22px',
  height: '22px',
}))

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  overflowY: 'scroll',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
  // on small screens, the drawer should slide over top of the main content
  [theme.breakpoints.down('sm')]: {
    position: 'fixed',
    // set zIndex to 1300 to ensure the drawer is above the sticky table headers (zIndex 2) as well as the sticky nav (zIndex 1200)
    zIndex: 1300,
  },
}))

const ListContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 5,
  marginTop: theme.spacing(1),
}))

const UpgradeWidgetContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: theme.spacing(3, 1.5),
}))

const DrawerHeaderLayout = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  paddingTop: theme.spacing(3),
  minHeight: upperNavHeight,
  backgroundColor: theme.palette.base.white,
  borderBottom: `1px solid ${theme.palette.border.light}`,
  zIndex: 1301,
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(1),
    minHeight: upperNavHeightMobile,
  },
}))

const LogoContainer = styled(Link)({
  display: 'flex',
  height: '95px',
})

const MainLogo = styled('img', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: open ? '214px' : '64px',
  height: 'auto',
  maxHeight: '93.4px',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  [theme.breakpoints.down('sm')]: {
    width: open ? '214px' : '48px',
  },
}))

const MenuItem = styled(ListItemButton, {
  shouldForwardProp: (prop) =>
    prop !== 'selected' && prop !== 'open' && prop !== 'spacer',
})(({ theme, open, selected, spacer }) => ({
  minHeight: 48,
  justifyContent: open ? 'initial' : 'center',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  backgroundColor: selected && theme.palette.secondary.main,
  borderRadius: theme.shape.borderRadius.xs,
  '&:hover': {
    backgroundColor: selected && theme.palette.secondary.light,
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: spacer ? 24 : 48,
    padding: spacer ? 0 : '',
  },
}))

const IconWrapper = styled(ListItemIcon, {
  shouldForwardProp: (prop) =>
    prop !== 'selected' && prop !== 'open' && prop !== 'type',
})(({ theme, open, selected, type }) => ({
  minWidth: 0,
  marginRight: open ? theme.spacing(1.5) : 0,
  justifyContent: 'center',
  '& svg path': {
    fill:
      type === 'Talks' || type === 'Settings' || type === 'Refer & Earn'
        ? ''
        : selected
        ? theme.palette.base.white
        : '',
    stroke:
      type === 'Talks' || type === 'Settings' || type === 'Refer & Earn'
        ? selected
          ? theme.palette.base.white
          : ''
        : '',
  },
}))

const MenuItemIconContainer = styled('div')({
  display: 'flex',
  alightItems: 'center',
  position: 'relative',
})

const MenuItemText = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'open',
})(({ theme, open, selected }) => ({
  display: !open && 'none',
  margin: 0,
  p: {
    fontWeight: 600,
    color: selected && theme.palette.base.white,
  },
}))

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  borderRight: 'none',
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: drawerWidthMobile,
  borderRight: 'none',
  [theme.breakpoints.up('sm')]: {
    width: drawerWidthCollapsed,
  },
})

const SubMenuContainer = styled('div')(({ theme, selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: theme.spacing(3),
  marginTop: selected ? theme.spacing(1) : 0,
  height: selected ? 'auto' : 0,
  overflow: 'hidden',
}))

const SubMenuItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(3),
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.surface.subtle,
  },
  transition: theme.transitions.create('background-color', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
}))

const SubMenuItemIcon = styled('div')(({ theme, selected }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  border: selected
    ? `1px solid ${theme.palette.neutral.extraLight}`
    : `2px solid ${theme.palette.neutral.extraLight}`,
  backgroundColor: selected ? theme.palette.secondary.main : 'transparent',
}))

const SubMenuItemText = styled(Typography)(({ theme, selected }) => ({
  marginLeft: theme.spacing(1),
  color: selected ? theme.palette.secondary.main : theme.palette.text.navText,
}))

const MultiContentWrapper = styled('span')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const BadgeIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '-6px',
  right: '-7px',
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  border: `0.75px solid ${theme.palette.base.white}`,
  backgroundColor: theme.palette.secondary.main,
}))

const LeftNav = ({
  open,
  toggleDrawer,
  handleAutoOpenDrawer,
  handleAutoCloseDrawer,
}) => {
  const {
    updateCounter,
    authData: {
      user,
      user: {
        counters: {
          unviewedEvents,
          unviewedLeads,
          unviewedCommunityNotifications,
        },
      },
    },
  } = useContext(AuthenticationContext)
  const location = useLocation()
  const history = useHistory()
  const {
    groupState: { selectedGroup },
  } = useContext(GroupContext)
  const { canAccessTeams } = usePermissionHelper()
  const pathName = location.pathname
  const isChapterPage = useIsChapterPage()

  const [isScrolling, setIsScrolling] = useState(false)
  const touchStartY = useRef(0)

  const GROUP_NAV_ITEMS = [
    {
      icon: <NewIcon />,
      content: isChapterPage ? 'My Chapters' : 'Dashboard',
      isSelected: pathName === '/dashboard' || pathName === '/groups',
      handleClick: isChapterPage
        ? () => history.push('/groups')
        : () => history.push('/dashboard'),
    },
    ...(selectedGroup
      ? [
          {
            icon: <GroupEventsIcon />,
            content: 'Events',
            isSelected: pathName.includes('events'),
            disabled: !selectedGroup,
            // NOTE: this is kind of redundant since we're already checking for selectedGroup above
            // but I'm leaving it here for now in case we want to show the menu item regardless of whether a group is selected
            handleClick: () =>
              selectedGroup &&
              history.push(`/groups/${selectedGroup?.id}/events`),
          },
        ]
      : []),
    {
      icon: <></>,
      content: '',
      isSelected: false,
      disabled: true,
    },
    {
      icon: <SettingsIcon />,
      content: 'Settings',
      isSelected: pathName.includes('account'),
      handleClick: () => history.push('/account/settings'),
    },
    // {
    //   key: 2,
    //   icon: <GroupMembersIcon />,
    //   content: 'Chapters',
    //   isSelected: pathName.includes('members'),
    //   link: `/members`,
    // },
  ]

  const SPEAKER_NAV_ITEMS = [
    {
      icon: <NewIcon />,
      content: 'Home',
      isSelected: pathName === '/dashboard' || pathName === '/',
      handleClick: () => history.push('/dashboard'),
    },
    {
      icon: <></>,
      content: '',
      isSelected: false,
      disabled: true,
      spacer: true,
    },
    {
      icon:
        !open && !selectedGroup && unviewedEvents > 0 ? (
          <MenuItemIconContainer>
            <TalksIcon />
            <BadgeIcon />
          </MenuItemIconContainer>
        ) : (
          <TalksIcon />
        ),
      identifier: 'Talks',
      content:
        !selectedGroup && unviewedEvents > 0 ? (
          <MultiContentWrapper>
            <span>Talks</span>
            <TableChip
              variant={'body1'}
              size={'medium'}
              fontWeight={'semiBold'}
              color={'lightBlue'}
              text={unviewedEvents}
            />
          </MultiContentWrapper>
        ) : (
          'Talks'
        ),
      isSelected: pathName.includes('talks'),
      handleClick: () => history.push('/talks'),
      hidden: user?.isEventPlanner,
    },
    {
      icon: <CodesIcon />,
      content: 'Codes',
      isSelected: pathName.includes('codes'),
      handleClick: () => history.push('/codes'),
      hidden: user?.isEventPlanner,
      ...(user?.isAdmin && {
        subMenuItems: [
          // NOTE: this is a temporary solution to allow admins to access the custom codes page
          {
            icon: <></>,
            content: 'Custom Codes',
            isSelected: pathName === '/custom-codes',
            handleClick: () => history.push('/custom-codes'),
          },
          {
            icon: <></>,
            content: 'Add a Code',
            isSelected: pathName.includes('codes/edit'),
            handleClick: () => history.push('/codes/edit'),
          },
        ],
      }),
    },
    {
      icon:
        !open && !selectedGroup && unviewedLeads > 0 ? (
          <MenuItemIconContainer>
            <LeadsIcon />
            <BadgeIcon />
          </MenuItemIconContainer>
        ) : (
          <LeadsIcon />
        ),
      content:
        !selectedGroup && unviewedLeads > 0 ? (
          <MultiContentWrapper>
            <span>Leads</span>
            <TableChip
              variant={'body1'}
              size={'medium'}
              fontWeight={'semiBold'}
              color={'lightBlue'}
              text={unviewedLeads}
            />
          </MultiContentWrapper>
        ) : (
          'Leads'
        ),
      isSelected: pathName.includes('leads'),
      handleClick: () => history.push('/leads'),
      subMenuItems: [
        {
          icon: <></>,
          content: 'My Leads',
          isSelected: pathName === '/leads',
          handleClick: () => history.push('/leads'),
        },
        {
          icon: <></>,
          content: 'Leads followup',
          isSelected: pathName.includes('leads/follow-up'),
          handleClick: () => history.push('/leads/follow-up'),
        },
        {
          icon: <></>,
          content: 'Integrations',
          isSelected: pathName.includes('leads/integrations'),
          handleClick: () => history.push('/leads/integrations'),
        },
      ],
    },
    {
      icon: <></>,
      content: '',
      isSelected: false,
      disabled: true,
      spacer: true,
    },
    {
      icon: <ResourcesIcon />,
      content: (
        <MultiContentWrapper>
          <span>Resources</span>
          <ExternalLinkIcon />
        </MultiContentWrapper>
      ),
      isSelected: pathName.includes('resources'),
      // Take them to resources even if they aren't a user in circles, since
      // the resource pages are public and dont require an account
      handleClick: () =>
        window.open('https://talk.talkadot.com/c/resources', '_blank'),
    },
    {
      icon:
        !open && unviewedCommunityNotifications > 0 ? (
          <MenuItemIconContainer>
            <CommunityIcon />
            <BadgeIcon />
          </MenuItemIconContainer>
        ) : (
          <CommunityIcon />
        ),
      content:
        unviewedCommunityNotifications > 0 ? (
          <MultiContentWrapper>
            <span>Community</span>
            <TableChip
              variant={'body1'}
              size={'medium'}
              fontWeight={'semiBold'}
              color={'lightBlue'}
              text={unviewedCommunityNotifications}
            />
          </MultiContentWrapper>
        ) : (
          <MultiContentWrapper>
            <span>Community</span>
            <ExternalLinkIcon />
          </MultiContentWrapper>
        ),
      isSelected: pathName.includes('community'),
      handleClick: () => {
        updateCounter({ unviewedCommunityNotifications: 0 })
        window.open('https://talk.talkadot.com/sign_in', '_blank')
      },
    },
    {
      icon: <HeadphoneIcon />,
      content: (
        <MultiContentWrapper>
          <span>Onboarding</span>
          <ExternalLinkIcon />
        </MultiContentWrapper>
      ),
      isSelected: pathName.includes('onboarding'),
      handleClick: () =>
        window.open(
          'https://talk.talkadot.com/c/onboardings/?sort=asc',
          '_blank'
        ),
    },
    {
      icon: <StyledGiftIcon />,
      identifier: 'Refer & Earn',
      content: 'Refer & Earn',
      isSelected: pathName.includes('refer-and-earn'),
      handleClick: () => history.push('/refer-and-earn'),
    },
    {
      icon: <></>,
      content: '',
      isSelected: false,
      disabled: true,
      spacer: true,
    },
    {
      icon: <SettingsIcon />,
      identifier: 'Settings',
      content: 'Settings',
      isSelected: pathName.includes('account'),
      handleClick: () => history.push('/account/settings'),
      subMenuItems: [
        {
          icon: <></>,
          content: 'Account',
          isSelected: pathName.includes('account/settings'),
          handleClick: () => history.push('/account/settings'),
        },
        {
          icon: <></>,
          content: 'Plan & Billing',
          isSelected: pathName.includes('account/billing'),
          handleClick: () => history.push('/account/billing'),
        },
      ].concat(
        canAccessTeams()
          ? [
              {
                icon: <></>,
                content: 'Team Management',
                isSelected: pathName.includes('account/team'),
                handleClick: () => history.push('/account/team'),
              },
            ]
          : []
      ),
    },
  ]

  // TODO: Refactor mobile drawer to burger menu
  const handleOnTouch = ({ isSelected, handleClick }) => {
    if (isSelected) {
      toggleDrawer()
    } else {
      if (!open) {
        toggleDrawer()
      }
      handleClick()
    }
  }

  const handleTouchStart = (e) => {
    touchStartY.current = e.touches[0].clientY
    setIsScrolling(false)
  }

  const handleTouchMove = (e) => {
    const touchCurrentY = e.touches[0].clientY
    const deltaY = Math.abs(touchCurrentY - touchStartY.current)
    if (deltaY > 5) {
      // Adjust this threshold as needed
      setIsScrolling(true)
    }
  }

  const handleTouchEnd = ({ isSelected, handleClick }) => {
    if (!isScrolling) {
      handleOnTouch({ isSelected, handleClick })
    }
    setIsScrolling(false)
  }

  const renderNavItems = (navItems) => {
    return navItems.map(
      (
        {
          icon,
          identifier,
          content,
          isSelected,
          disabled,
          handleClick,
          subMenuItems,
          spacer,
          hidden,
        },
        i
      ) => {
        return hidden ? (
          <Fragment key={`nav-item-${i}`}></Fragment>
        ) : (
          <Fragment key={`nav-item-${i}`}>
            <ListItem key={`nav-item-${i}`} disablePadding>
              <MenuItem
                selected={isSelected}
                open={open}
                disabled={disabled}
                spacer={spacer}
                onClick={handleClick}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={() => handleTouchEnd({ isSelected, handleClick })}
                onPointerEnter={handleAutoOpenDrawer}>
                <IconWrapper
                  open={open}
                  selected={isSelected}
                  type={identifier}>
                  {icon}
                </IconWrapper>
                <MenuItemText
                  primary={content}
                  open={open}
                  selected={isSelected}
                  primaryTypographyProps={{ variant: 'body2' }}
                />
              </MenuItem>
            </ListItem>
            {open && subMenuItems && (
              <SubMenuContainer selected={isSelected}>
                {subMenuItems.map(({ content, isSelected, handleClick }, j) => (
                  <SubMenuItem
                    key={`sub-menu-${j}`}
                    onClick={handleClick}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={() =>
                      handleTouchEnd({ isSelected, handleClick })
                    }>
                    <SubMenuItemIcon selected={isSelected} />
                    <SubMenuItemText selected={isSelected}>
                      {content}
                    </SubMenuItemText>
                  </SubMenuItem>
                ))}
              </SubMenuContainer>
            )}
          </Fragment>
        )
      }
    )
  }

  const renderMainNavItems = () => {
    const navItems =
      pathName.includes('groups') && !pathName.includes('event-planner')
        ? GROUP_NAV_ITEMS
        : SPEAKER_NAV_ITEMS

    return renderNavItems(navItems)
  }

  return (
    <StyledDrawer
      variant="permanent"
      open={open}
      onPointerLeave={handleAutoCloseDrawer}>
      <DrawerHeaderLayout>
        <LogoContainer to={{ pathname: '/dashboard' }}>
          <MainLogo
            src={`https://d3qvkz8495pjoq.cloudfront.net/talkadotLogo${
              open ? 'Lg' : 'Sm'
            }.svg`}
            alt="Talkadot!"
            open={open}
          />
        </LogoContainer>
      </DrawerHeaderLayout>
      <ListContainer>
        <List>{renderMainNavItems()}</List>
      </ListContainer>
      <UpgradeWidgetContainer>
        {open ? (
          <UpgradeWidget />
        ) : (
          user.membership.shouldShowUpgrades && (
            <UpgradeButton showText={false} />
          )
        )}
      </UpgradeWidgetContainer>
    </StyledDrawer>
  )
}

export default LeftNav
