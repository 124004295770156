import { useState, forwardRef } from 'react'
import { styled } from '@mui/system'
import { Alert, IconButton } from '@mui/material'

import { ReactComponent as AlertIcon } from '../../icons/alertIcon.svg'
import { ReactComponent as DangerIcon } from '../../icons/dangerIcon.svg'
import { ReactComponent as CircleXIcon } from '../../icons/circleXIcon.svg'
import { ReactComponent as CircleCheckIcon } from '../../icons/circleCheckIcon.svg'
import { ReactComponent as CloseIcon } from '../../icons/closeIcon.svg'

const BlackExclamationIcon = styled(AlertIcon)(({ theme }) => ({
  path: {
    fill: theme.palette.neutral.dark,
    stroke: theme.palette.base.white,
  },
}))

const WhiteExclamationIcon = styled(AlertIcon)(({ theme }) => ({
  path: {
    fill: theme.palette.base.white,
    stroke: theme.palette.neutral.main,
  },
}))

const BlueExclamationIcon = styled(AlertIcon)(({ theme }) => ({
  path: {
    fill: theme.palette.base.white,
    stroke: theme.palette.secondary.main,
  },
}))

const RedExclamationIcon = styled(AlertIcon)(({ theme }) => ({
  path: {
    fill: theme.palette.error.semiDark,
    stroke: theme.palette.base.white,
  },
}))

const OrangeDangerIcon = styled(DangerIcon)(({ theme }) => ({
  path: {
    stroke: theme.palette.primary.main,
  },
}))

const ErrorIcon = styled(CircleXIcon)(({ theme }) => ({
  path: {
    fill: theme.palette.base.white,
    stroke: theme.palette.error.semiDark,
  },
}))

const SuccessIcon = styled(CircleCheckIcon)(({ theme }) => ({
  path: {
    fill: theme.palette.base.white,
    stroke: theme.palette.success.semiDark,
  },
}))

const StyledAlert = styled(Alert, {
  shouldForwardProp: (prop) => prop !== 'maxWidth',
})(({ theme, maxWidth }) => ({
  // TODO move these overrides into theme file
  display: 'flex',
  padding: theme.spacing(1.5, 2.5),
  alignItems: 'center',
  gap: theme.spacing(2.5),
  maxWidth: maxWidth || '720px',
  marginBottom: theme.spacing(2),
  '& .MuiAlert-icon': {
    marginRight: '0',
    padding: '0',
    fontSize: 'inherit',
    opacity: 1,
    '& svg': {
      width: '22px',
      height: '22px',
      flexShrink: 0,
    },
  },
  '& .MuiAlert-message': {
    padding: '0',
  },
  '& .MuiAlert-action': {
    padding: '0',
    marginLeft: 'auto',
    marginRight: '0',
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1.5),
    padding: theme.spacing(1.5, 1),
  },
}))

const CloseButton = styled(IconButton)({
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
})

const iconMap = {
  greyWarning: BlackExclamationIcon,
  whiteWarning: WhiteExclamationIcon,
  blueWarning: BlueExclamationIcon,
  orangeWarning: OrangeDangerIcon,
  redWarning: RedExclamationIcon,
  subtlWarning: BlackExclamationIcon,
  error: ErrorIcon,
  success: SuccessIcon,
}

const Notification = forwardRef(
  (
    {
      variant,
      onClose,
      children,
      hideIcon = false,
      hideClose = false,
      maxWidth = false,
    },
    ref
  ) => {
    const [visible, setVisible] = useState(true)

    const handleClose = () => {
      if (onClose) {
        onClose()
      } else {
        setVisible(false)
      }
    }

    if (!visible) return null

    const IconComponent = iconMap[variant]

    return (
      <StyledAlert
        ref={ref}
        variant={variant}
        maxWidth={maxWidth}
        icon={!hideIcon && <IconComponent />}
        action={
          !hideClose && (
            <CloseButton aria-label="close" onClick={handleClose}>
              <CloseIcon fontSize="inherit" />
            </CloseButton>
          )
        }>
        {children}
      </StyledAlert>
    )
  }
)

const PersistentNotifications = ({ notificationDetails }) => {
  const renderNotifications = () => {
    return notificationDetails.map((notificationDetail, index) => {
      const { content, onClose, variant } = notificationDetail

      return (
        <Notification key={index} variant={variant} onClose={onClose}>
          {content}
        </Notification>
      )
    })
  }

  return <>{renderNotifications()}</>
}

export { Notification, PersistentNotifications }
