import { styled } from '@mui/system'
import { IconButton, Tooltip } from '@mui/material'

import { ReactComponent as EyeIcon } from '../../../icons/eyeIcon_16x16.svg'
import { ReactComponent as EyeOffIcon } from '../../../icons/eyeOffIcon_16x16.svg'

const ToggleQuestionButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'enabled' && prop !== 'iconColor',
})(({ theme, enabled, iconColor }) => ({
  svg: {
    path: {
      stroke: enabled
        ? iconColor || theme.palette.neutral.veryDark
        : theme.palette.error.semiDark,
    },
  },
}))

const CodeBuilderToggleQuestionButton = ({
  handleToggleQuestion,
  enabled,
  iconColor,
}) => {
  return (
    <Tooltip
      title={
        enabled
          ? 'Question is enabled. Toggle to disable question.'
          : 'Question is disabled, it will not be shown when you use this code. Toggle to enable question.'
      }
      arrow>
      <ToggleQuestionButton
        onClick={handleToggleQuestion}
        enabled={enabled}
        iconColor={iconColor}>
        {enabled ? <EyeIcon /> : <EyeOffIcon />}
      </ToggleQuestionButton>
    </Tooltip>
  )
}

export default CodeBuilderToggleQuestionButton
