import { useEffect, useState, useContext, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { styled } from '@mui/system'
import { useMediaQuery, Typography } from '@mui/material'

import PageHeader from '../../common/PageHeader'
import CodeBuilderQuestion from './CodeBuilderQuestion'
import CodeBuilderNavigation from './CodeBuilderNavigation'
import CodeBuilderPreviewPaneParent from './CodeBuilderPreviewPaneParent'
import PageContainer from '../../common/PageContainer'
import LoadingSpinner from '../../common/LoadingSpinner'
import ConfirmDialog from '../../common/ConfirmDialog'
import { Notification } from '../../common/Notification'

import { ReactComponent as ArrowRight } from '../../../icons/arrowRight.svg'
import { ReactComponent as DownloadIcon } from '../../../icons/downloadIcon_16x16.svg'

import { AuthenticationContext } from '../../authentication/authenticationContext'
import Api from '../../../services/api'
import { downloadSlideAsPng } from '../../common/helpers'

import { randomCodeString } from '../../../utils/string'

const CodeBuilderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(5),
}))

const QuestionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  height: 'min-content',
  padding: theme.spacing(5, 2.5),
  backgroundColor: theme.palette.base.white,
}))

const PreviewPaneContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'activeTab',
})(({ activeTab }) => ({
  position: 'sticky',
  top: '164px',
  height: 'calc(100vh - 260px)',
  maxHeight: activeTab === 1 ? '655px' : '',
  overflowY: 'hidden',
}))

const MultiQuestionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4.5),
}))

const LoadingSpinnerContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50vh',
  width: '100%',
})

const initialQuestionState = {
  questionType: '',
}

export const codeQuestionState = {
  id: 'code',
  questionType: 'code',
  enabled: true,
  sortable: false,
  canDisable: false,
  config: {
    previewName: 'General Setup',
    questionTypeDescription: 'Setup Code',
  },
}

export const bonusQuestionState = {
  id: 'bonus',
  questionType: 'bonus',
  enabled: true,
  sortable: false,
  canDisable: false,
  config: {
    previewName: 'Bonus and Socials',
    questionTypeDescription: 'Bonus Uploader',
  },
}

export const downloadSlideQuestionState = {
  id: 'downloadSlide',
  questionType: 'downloadSlide',
  enabled: true,
  sortable: false,
  canDisable: false,
  config: {
    previewName: 'Download Slide',
    questionTypeDescription: 'Download Slide',
  },
}

const bookingLinkQuestionState = {
  id: 'bookingLink',
  questionType: 'bookingLink',
  enabled: true,
  config: {
    questionTypeDescription: 'Calendar Booking',
  },
}

const socialLinksQuestionState = {
  id: 'socialLinks',
  questionType: 'socialLinks',
  enabled: true,
  config: {
    questionTypeDescription: 'Social Links',
  },
}

const CodeBuilderParent = () => {
  const [surveyOffer, setSurveyOffer] = useState({})
  const [code, setCode] = useState('')
  const [bonusUrl, setBonusUrl] = useState('')
  const [description, setDescription] = useState('')
  const [questions, setQuestions] = useState([])
  const [availableQuestionTypes, setAvailableQuestionTypes] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState(initialQuestionState)
  const [currentQuestionId, setCurrentQuestionId] = useState('')
  const [surveyOfferLoading, setSurveyOfferLoading] = useState(false)
  const [questionLoading, setQuestionLoading] = useState(false)
  const [downloadSlideLoading, setDownloadSlideLoading] = useState(false)
  const [createCodeSkip, setCreateCodeSkip] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(1)
  const { surveyOfferId } = useParams()
  const history = useHistory()
  const slideRef = useRef()

  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const { authData, setNotification } = useContext(AuthenticationContext)
  const { user } = authData

  const offerBonusUrl = (surveyOffer) => {
    const { selectedBonusType, downloadUrl, link } = surveyOffer
    switch (selectedBonusType) {
      case 'file':
        return downloadUrl
      case 'link':
        return link
      default:
        return ''
    }
  }

  useEffect(() => {
    const getCode = async (surveyOfferId) => {
      try {
        setSurveyOfferLoading(true)
        const res = await Api.getCode(surveyOfferId)

        if (!res.errors) {
          const questions = res.questions

          setSurveyOffer(res)
          setQuestions([
            codeQuestionState,
            ...questions,
            bonusQuestionState,
            downloadSlideQuestionState,
          ])
          setCode(res.code)
          setBonusUrl(offerBonusUrl(res))
          setDescription(res.name)

          if (createCodeSkip) {
            setCurrentQuestionId('bonus')
            setCreateCodeSkip(false)
          } else {
            setCurrentQuestionId(questions[0].id)
          }

          setSurveyOfferLoading(false)
        } else {
          // handle error
          throw res.errors
        }
      } catch (err) {
        setSurveyOfferLoading(false)
        return setNotification(err)
      }
    }

    // if surveyOfferId is not null, fetch the survey offer
    // this means the user is editing an existing survey offer
    if (surveyOfferId && surveyOffer.id !== surveyOfferId) {
      // fetch survey offer
      getCode(surveyOfferId)
      return
    }

    // if surveyOfferId is null, set currentQuestionId to 'code'
    // this means the user is creating a new survey offer
    setCurrentQuestionId('code')
    setQuestions([codeQuestionState])
    setCode(randomCodeString(4))
  }, [surveyOfferId])

  useEffect(() => {
    const fetchAvailableQuestionTypes = async () => {
      try {
        const res = await Api.getAvailableQuestionTypes()

        if (!res.errors) {
          setAvailableQuestionTypes(res)
        } else {
          throw res.errors
        }
      } catch (err) {
        return setNotification(err)
      }
    }

    fetchAvailableQuestionTypes()
  }, [])

  useEffect(() => {
    setCurrentQuestion(
      questions?.find((question) => question.id === currentQuestionId)
    )
  }, [questions, currentQuestionId])

  useEffect(() => {
    setBonusUrl(offerBonusUrl(surveyOffer))
  }, [surveyOffer.selectedBonusType])

  const handleRefreshQuestionState = (questionResponse) => {
    const updatedQuestions = questions.map((question) => {
      const updatedQuestion = questionResponse.find((q) => q.id === question.id)
      if (updatedQuestion) {
        return updatedQuestion
      }
      return question
    })

    setQuestions(updatedQuestions)
  }

  const createNewCode = async () => {
    try {
      setSurveyOfferLoading(true)
      const res = await Api.createCode({
        survey_offer: { code, name: description },
      })

      if (!res.errors) {
        history.push(`/codes/edit/${res.id}`)
      } else {
        // handle error
        throw res.errors
      }
    } catch (err) {
      setSurveyOfferLoading(false)
      setNotification(err)
    }
  }

  const updateCode = async (data) => {
    if (!surveyOffer.id) return

    try {
      setSurveyOfferLoading(true)

      const params = {
        survey_offer: {
          ...data,
        },
      }

      const res = await Api.updateCode(surveyOffer.id, params)

      if (!res.errors) {
        setSurveyOffer(res)

        if (code !== res.code) {
          setCode(res.code)
        }

        const offerBonusUrl = res.downloadUrl || res.link

        if (bonusUrl !== offerBonusUrl) {
          setBonusUrl(offerBonusUrl)
        }

        setSurveyOfferLoading(false)
      } else {
        // handle error
        throw res.errors
      }
    } catch (err) {
      setSurveyOfferLoading(false)
      return setNotification(err)
    }
  }

  const changeQuestionType = async (questionId, data) => {
    try {
      setQuestionLoading(true)

      const params = {
        question: {
          ...data,
        },
      }

      const res = await Api.changeQuestionType(questionId, params)

      if (!res.errors) {
        handleRefreshQuestionState(res.updatedQuestions)
        setQuestionLoading(false)
      } else {
        throw res.errors
      }
    } catch (err) {
      setQuestionLoading(false)
      return setNotification(err)
    }
  }

  const updateQuestion = async (questionId, data) => {
    try {
      setQuestionLoading(true)

      const params = {
        question: {
          ...data,
        },
      }

      const res = await Api.updateQuestion(questionId, params)

      if (!res.errors) {
        handleRefreshQuestionState(res.updatedQuestions)
        setQuestionLoading(false)
      } else {
        // handle error
        throw res.errors
      }
    } catch (err) {
      setQuestionLoading(false)
      return setNotification(err)
    }
  }

  const downloadSlideCallback = () => {
    // disable the buttons while the file browser is opening
    setTimeout(() => {
      setDownloadSlideLoading(false)
    }, 500)
  }

  const missingBonus = () => {
    return ['link', 'file'].includes(surveyOffer.selectedBonusType) && !bonusUrl
  }

  const handleBack = () => {
    const currentIndex = questions.findIndex(
      (question) => question.id === currentQuestionId
    )
    // if the question we're about to go back to is not enabled,
    // and it has a groupingIdentifier, skip to the first question
    // with that groupingIdentifier
    const previousQuestion = questions[currentIndex - 1]
    if (
      previousQuestion &&
      !previousQuestion.enabled &&
      previousQuestion.config.groupingIdentifier >= 0
    ) {
      const previousQuestions = questions.slice(0, currentIndex)
      const firstQuestionInGroup = previousQuestions.find(
        (question) =>
          question.config.groupingIdentifier ===
          previousQuestion.config.groupingIdentifier
      )

      if (firstQuestionInGroup) {
        setCurrentQuestionId(firstQuestionInGroup.id)
        return
      }
    }

    if (currentIndex > 0) {
      setCurrentQuestionId(questions[currentIndex - 1].id)
    }
  }

  const handleNext = () => {
    // if surveyOffer.id does not exist, create a new survey offer
    if (!surveyOffer.id && currentQuestionId === 'code') {
      createNewCode()
    }

    const currentIndex = questions.findIndex(
      (question) => question.id === currentQuestionId
    )

    // if the current question is the bonus question, and the selected bonus type is 'link' or 'file'
    // and the bonusUrl is empty, show an error message
    if (currentQuestionId === 'bonus' && missingBonus()) {
      setConfirmDialogOpen(true)
      return
    }

    // if currentQuestion is not enabled, and it has a groupingIdentifier
    // skip to the first question where groupingIdentifier does not equal the current question's groupingIdentifier
    if (
      !currentQuestion.enabled &&
      currentQuestion.config.groupingIdentifier >= 0
    ) {
      const nextQuestions = questions.slice(currentIndex + 1)
      const nextQuestionNotInGroup = nextQuestions.find(
        (question) =>
          question.config.groupingIdentifier !==
          currentQuestion.config.groupingIdentifier
      )

      if (nextQuestionNotInGroup) {
        setCurrentQuestionId(nextQuestionNotInGroup.id)
        return
      }
    }

    if (currentIndex >= 0 && currentIndex < questions.length - 1) {
      setCurrentQuestionId(questions[currentIndex + 1].id)
    }

    if (currentQuestionId === 'downloadSlide') {
      setDownloadSlideLoading(true)
      downloadSlideAsPng(
        slideRef,
        `Talkadot-Slide-${user.shortlink}-${code}`,
        downloadSlideCallback
      )
    }
  }

  const handleSkip = async () => {
    // When creating a new code, skip the code question and go straight to the bonus question
    if (currentQuestionId === 'code' && !surveyOffer.id) {
      setCreateCodeSkip(true)
      await createNewCode()
    }

    // When on the download slide question, triggers on "Finish" button click
    if (currentQuestionId === 'downloadSlide') {
      // TODO: Update this routing once the feature flag is removed
      history.push('/custom-codes')
    }
  }

  const questionNumber = () => {
    return (
      questions.findIndex((question) => question.id === currentQuestion.id) + 1
    )
  }

  // Parse the currently selected emoji keywords
  const emojiKeywords = () => {
    const emojiQuestions = questions.filter(
      (question) => question.questionType === 'EMOJI_SCALE'
    )

    return emojiQuestions.map((question) => question.replacementValue)
  }

  const renderQuestions = () => {
    if (currentQuestionId === 'bonus') {
      return (
        <MultiQuestionContainer>
          <CodeBuilderQuestion
            surveyOffer={surveyOffer}
            question={currentQuestion}
            questionNumber={questionNumber()}
            code={code}
            setCode={setCode}
            updateCode={updateCode}
            setActiveTab={setActiveTab}
          />
          <CodeBuilderQuestion
            surveyOffer={surveyOffer}
            question={bookingLinkQuestionState}
            questionNumber={questionNumber() + 'a'}
            code={code}
            setCode={setCode}
            setActiveTab={setActiveTab}
          />
          <CodeBuilderQuestion
            surveyOffer={surveyOffer}
            question={socialLinksQuestionState}
            questionNumber={questionNumber() + 'b'}
            code={code}
            setCode={setCode}
            setActiveTab={setActiveTab}
          />
        </MultiQuestionContainer>
      )
    }

    return (
      <CodeBuilderQuestion
        surveyOffer={surveyOffer}
        question={currentQuestion}
        questionNumber={questionNumber()}
        updateQuestion={updateQuestion}
        changeQuestionType={changeQuestionType}
        setCurrentQuestion={setCurrentQuestion}
        user={user}
        code={code}
        setCode={setCode}
        description={description}
        setDescription={setDescription}
        updateCode={updateCode}
        surveyOfferLoading={surveyOfferLoading}
        questionLoading={questionLoading}
        speakerName={user?.first_name}
        emojiKeywords={emojiKeywords()}
        availableQuestionTypes={availableQuestionTypes}
        slideRef={slideRef}
        setActiveTab={setActiveTab}
      />
    )
  }

  const nextButtonContent = () => {
    if (currentQuestionId === 'downloadSlide') {
      return (
        <>
          <DownloadIcon />
          <span className="button-text">Download</span>
        </>
      )
    }

    return (
      <>
        <span className="button-text">Next</span>
        <ArrowRight />
      </>
    )
  }

  const skipButtonContent = () => {
    if (currentQuestionId === 'downloadSlide') {
      return 'Finish'
    }

    if (currentQuestionId === 'code') {
      if (isSmallScreen) {
        return 'Use defaults'
      }

      if (isLargeScreen) {
        return 'I want to use all Talkadot defaults. Go straight to add a bonus'
      }

      return 'Use defaults. Add a bonus'
    }
  }

  const showBuilderSkipButton = () => {
    if (
      (!surveyOffer?.id && currentQuestion.questionType === 'code') ||
      currentQuestionId === 'downloadSlide'
    ) {
      return true
    }

    return false
  }

  const renderContent = () => {
    if (surveyOfferLoading && !currentQuestion?.questionType) {
      return (
        <LoadingSpinnerContainer>
          <LoadingSpinner size={50} />
        </LoadingSpinnerContainer>
      )
    }

    if (currentQuestion?.questionType) {
      return (
        <>
          <QuestionContainer>
            {currentQuestion?.config?.alert && (
              <Notification
                variant="subtleWarning"
                hideIcon={true}
                hideClose={true}
                maxWidth={true}>
                {currentQuestion.config.alert}
              </Notification>
            )}
            {renderQuestions()}
            <CodeBuilderNavigation
              nextButtonContent={nextButtonContent()}
              skipButtonContent={skipButtonContent()}
              handleBack={handleBack}
              handleNext={handleNext}
              handleSkip={handleSkip}
              showBackButton={currentQuestion.questionType !== 'code'}
              showBuilderSkipButton={showBuilderSkipButton()}
              loading={
                surveyOfferLoading || questionLoading || downloadSlideLoading
              }
            />
          </QuestionContainer>
          <PreviewPaneContainer activeTab={activeTab}>
            <CodeBuilderPreviewPaneParent
              currentQuestion={currentQuestion}
              setCurrentQuestionId={setCurrentQuestionId}
              currentQuestionNumber={questionNumber()}
              updateQuestion={updateQuestion}
              questions={questions}
              user={user}
              code={code}
              bonusUrl={bonusUrl}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              missingBonus={missingBonus()}
            />
          </PreviewPaneContainer>
        </>
      )
    }
  }

  return (
    <PageContainer>
      <PageHeader header="Your Talkadot Code Setup" />
      <CodeBuilderContainer>{renderContent()}</CodeBuilderContainer>
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        variant="info"
        hideDangerMessage={true}
        showCancel={false}
        title="You are missing a bonus"
        buttonText="Add bonus"
        buttonColor="primary"
        textColor="white"
        setOpen={setConfirmDialogOpen}
        onConfirm={() => {
          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }, 200)
        }}>
        <Typography variant="body1">
          You have indicated you want to give away a bonus but have not uploaded
          a link or file. Please add a bonus before proceeding or the select the
          "I do not want to give away anything" option.
        </Typography>
      </ConfirmDialog>
    </PageContainer>
  )
}

export default CodeBuilderParent
