import { useEffect, useState, useRef } from 'react'
import { styled } from '@mui/system'

import CodeBuilderInputWrapper from './CodeBuilderInputWrapper'

const TextInput = styled('input', {
  shouldForwardProp: (prop) =>
    prop !== 'fontWeight' && prop !== 'error' && prop !== 'disableLeftPadding',
})(({ theme, fontWeight, error, disableLeftPadding }) => ({
  width: '100%',
  transition: '300ms all ease-in-out',
  fontFamily: 'Figtree, sans-serif',
  fontWeight: fontWeight || '400',
  fontSize: '0.875rem',
  border: 'none',
  position: 'relative',
  backgroundColor: 'transparent',
  color: error ? theme.palette.error.main : theme.palette.neutral.main,
  textOverflow: 'ellipsis',
  padding: '1px 10px',
  paddingLeft: disableLeftPadding ? '0' : '10px',
  height: '100%',
  outline: 'none',
  borderTopRightRadius: theme.shape.borderRadius.xxs,
  borderBottomRightRadius: theme.shape.borderRadius.xxs,
  '&:focus': {
    outline: 'none',
  },
  '&::placeholder': {
    color: theme.palette.neutral.light,
    opacity: '50%',
  },
}))

const CodeBuilderInputText = ({
  value,
  inputValidation,
  inputDescription,
  marginTop,
  hint,
  placeholder,
  fontWeight,
  canEdit,
  handleOnChange,
  handleOnBlur,
  disabled,
  error,
  setError,
  disableLeftPadding,
  prefix,
  autoFocus,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [isFocused, setIsFocused] = useState(false)

  const inputRef = useRef(null)

  useEffect(() => {
    if (value && value !== inputValue) {
      setInputValue(value)
    }

    if (canEdit && autoFocus) {
      setIsFocused(true)

      // NOTE: I don't love this timeout, but it's the only way I could get it to work
      // Need this timeout to allow the onBlur event to finish before setting focus
      // onBlur also runs on a timeout to allow the button click event to finish before sending the blur event
      setTimeout(() => {
        if (inputRef.current && document.activeElement !== inputRef.current) {
          inputRef.current.focus()
          inputRef.current.setSelectionRange(
            inputRef.current.value.length,
            inputRef.current.value.length
          )
        }
      }, 300)
    }

    if (!canEdit && isFocused) {
      setIsFocused(false)
    }
  }, [value, autoFocus])

  const handleChange = (e) => {
    if (!canEdit) {
      return
    }

    if (error) {
      setError(null)
    }

    const validationError = inputValidation && inputValidation(e.target.value)

    if (validationError?.message) {
      setError(validationError.message)
      if (!validationError?.allowContinue) {
        return
      }
    }

    setInputValue(e.target.value)
    handleOnChange(e.target.value)
  }

  const sendBlurEvent = async (newValue) => {
    // NOTE: I would like to skip the handleOnBlur if the value hasn't changed
    // but since we are modifying the value in the parent component, we can't compare
    // we could pass the original value to this component, but that seems like it might be overkill?

    // if (canEdit && !error && newValue !== value) {
    if (canEdit && !error) {
      await handleOnBlur(newValue)
    }
  }

  const handleBlur = async (e) => {
    setIsFocused(false)

    if (e.relatedTarget && e.relatedTarget.tagName === 'BUTTON') {
      // prevent onBlur from swallowing navigation events
      setTimeout(() => {
        sendBlurEvent(inputValue)
      }, 200)
    } else {
      sendBlurEvent(inputValue)
    }
  }

  return (
    <CodeBuilderInputWrapper
      error={error}
      marginTop={marginTop}
      hint={hint}
      canEdit={canEdit}
      inputDescription={inputDescription}
      isFocused={isFocused}>
      {prefix && prefix}
      <TextInput
        ref={inputRef}
        type="text"
        onChange={(e) => handleChange(e)}
        onBlur={(e) => handleBlur(e)}
        onFocus={() => setIsFocused(true)}
        value={inputValue}
        placeholder={placeholder}
        fontWeight={fontWeight}
        disabled={disabled || !canEdit}
        disableLeftPadding={disableLeftPadding}
      />
    </CodeBuilderInputWrapper>
  )
}

export default CodeBuilderInputText
