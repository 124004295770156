import { useState } from 'react'
import { styled } from '@mui/system'

import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Select,
  InputBase,
} from '@mui/material'

import { ReactComponent as OpenEndedIcon } from '../../../icons/questionTypeIcons/freeFlow.svg'
import { ReactComponent as BooleanIcon } from '../../../icons/questionTypeIcons/boolean.svg'
import { ReactComponent as MultipleChoiceIcon } from '../../../icons/questionTypeIcons/multipleChoice.svg'
import { ReactComponent as EmojiIcon } from '../../../icons/questionTypeIcons/emoji.svg'
import { ReactComponent as CaretDownIcon } from '../../../icons/caretDown_16x16.svg'

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'questionType' && prop !== 'epitome',
})(({ questionType, epitome, theme }) => ({
  fontFamily: 'Figtree, sans-serif',
  minHeight: '52px !important',
  '& .MuiListItemIcon-root': {
    minWidth: '50px',
  },
  backgroundColor: `${
    questionType === epitome && theme.palette.secondary.main
  } !important`,
  '&:hover': {
    backgroundColor: questionType === epitome && theme.palette.secondary.main,
  },
  '& svg path': {
    stroke: questionType === epitome ? theme.palette.base.white : '',
  },
  margin: '0 15px',
  borderRadius: theme.shape.borderRadius.xs,
}))

const StyledListItemText = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ selected, theme }) => ({
  span: {
    color: selected && theme.palette.base.white,
  },
}))

const StyledInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    width: '100%',
    fontFamily: 'Figtree, sans-serif',
    fontWeight: '400',
    fontSize: '0.875rem',
    border: 'none',
    position: 'relative',
    backgroundColor: 'transparent',
    color: theme.palette.neutral.main,
    textOverflow: 'ellipsis',
    padding: 0,
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: theme.palette.neutral.light,
      opacity: '50%',
    },
  },
}))

const StyledCaretIcon = styled(CaretDownIcon)(({ theme }) => ({
  path: {
    stroke: theme.palette.neutral.dark,
  },
}))

const CodeBuilderQuestionTypePicker = ({
  availableQuestionTypes,
  changeQuestionType,
  question,
}) => {
  const [value, setValue] = useState('')

  const updateQuestionType = (questionTypeId) => {
    setValue(questionTypeId)
    changeQuestionType(question.id, { question_type_id: questionTypeId })
  }

  const renderQuestionTypeIcon = (questionType) => {
    if (questionType === 'FREE_FLOW') {
      return <OpenEndedIcon />
    }

    if (questionType === 'BOOLEAN') {
      return <BooleanIcon />
    }

    if (questionType === 'EMOJI_SCALE') {
      return <EmojiIcon />
    }

    if (questionType === 'MULTIPLE_CHOICE') {
      return <MultipleChoiceIcon />
    }

    return <OpenEndedIcon />
  }

  return (
    <Select
      onChange={(e) => {
        updateQuestionType(e.target.value)
      }}
      input={<StyledInput />}
      value={value}
      renderValue={() => null}
      IconComponent={StyledCaretIcon}>
      {availableQuestionTypes?.map((questionType) => {
        return (
          <StyledMenuItem
            key={questionType.id}
            value={questionType.id}
            questionType={question.questionType}
            epitome={questionType.epitome}>
            <ListItemIcon>
              {renderQuestionTypeIcon(questionType.epitome)}
            </ListItemIcon>
            <StyledListItemText
              selected={question.questionType === questionType.epitome}>
              {questionType.label}
            </StyledListItemText>
          </StyledMenuItem>
        )
      })}
    </Select>
  )
}

export default CodeBuilderQuestionTypePicker
