import { useEffect, useState } from 'react'
import { styled } from '@mui/system'

import CodeBuilderInputText from './CodeBuilderInputText'
import CodeBuilderInputSelect from './CodeBuilderInputSelect'

import CheckBoxStyled from '../../common/CheckBoxStyled'

import {
  usePermissionHelper,
  CUSTOMIZATIONS,
} from '../../../utils/permission_helper'

import Api from '../../../services/api'

import {
  parseEmojiKeywords,
  parseSpeakerName,
  stripHtmlTags,
  stripEmojiTags,
} from '../../../utils/string'

const SettingsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  marginTop: theme.spacing(3),
}))

const CodeBuilderSettingsQuestion = ({
  question,
  updateQuestion,
  setCurrentQuestion,
  questionLoading,
  speakerName,
  emojiKeywords,
  setActiveTab,
}) => {
  const [error, setError] = useState(null)
  const [defaultKeywordOptions, setDefaultKeywordOptions] = useState([])
  const [keywordsLoading, setKeywordsLoading] = useState(false)

  const { config, questionType } = question

  const { findPermission } = usePermissionHelper()
  const customizationsPermission = findPermission(CUSTOMIZATIONS)

  useEffect(() => {
    if (questionType === 'EMOJI_SCALE' && !defaultKeywordOptions.length) {
      fetchKeywordOptions()
    }
  }, [questionType])

  const canEditContent =
    config?.canEditContent &&
    customizationsPermission?.enabled &&
    question.enabled

  const fetchKeywordOptions = async () => {
    try {
      setKeywordsLoading(true)

      const res = await Api.fetchDefaultEmojiKeywords()

      if (!res.errors) {
        setDefaultKeywordOptions(res)
        setKeywordsLoading(false)
      }
    } catch (err) {
      console.log(err)
      setKeywordsLoading(false)
    }
  }

  const textInputValidation = (value) => {
    if (value?.length === 0) {
      return {
        message: 'Value cannot be blank',
        allowContinue: true,
      }
    }

    if (value?.length > 100) {
      return {
        message: 'Value must be 100 characters or less',
        allowContinue: false,
      }
    }
  }

  const handleTextInputOnChange = ({ newValue, question }) => {
    setCurrentQuestion({ ...question, content: newValue })
    setActiveTab(1)
  }

  const handleSelectInputOnChange = ({ questionId, newValue }) => {
    updateQuestion(questionId, { replacement_value: newValue })
    setActiveTab(1)
  }

  const renderInput = () => {
    switch (questionType) {
      case 'EMOJI_SCALE':
        const parsedEmojiContent = stripHtmlTags(
          stripEmojiTags(parseSpeakerName(question, speakerName))
        )

        // If this is a custom question with emoji scale type (i.e., a non-talkadot-question)
        // We don't want to render the select options.  Instead we want to let the
        // user just type whatever content they want
        if (!question.config?.isTalkadotQuestion) {
          return (
            <CodeBuilderInputText
              label="Question"
              placeholder="Enter a question"
              value={parsedEmojiContent.content}
              canEdit={canEditContent}
              error={error}
              setError={setError}
              handleOnChange={(newValue) =>
                handleTextInputOnChange({ newValue, question })
              }
              handleOnBlur={(newValue) =>
                updateQuestion(question.id, { content: newValue })
              }
              inputValidation={textInputValidation}
              disabled={questionLoading}
              autoFocus={true}
            />
          )
        }

        // Filter out the currently selected emoji keywords except for one for the current question
        const availableKeywordOptions = defaultKeywordOptions.filter(
          (keyword) =>
            !emojiKeywords.includes(keyword.keyword) ||
            keyword.keyword === question.replacementValue
        )

        return (
          <CodeBuilderInputSelect
            placeholder="Select a keyword"
            value={question.replacementValue}
            options={availableKeywordOptions}
            disabled={questionLoading || keywordsLoading}
            handleOnChange={(newValue) =>
              handleSelectInputOnChange({ questionId: question.id, newValue })
            }
            parsedEmojiContent={parsedEmojiContent}
          />
        )
      default:
        const parsedContent = stripHtmlTags(
          parseSpeakerName(parseEmojiKeywords(question), speakerName)
        )

        return (
          <CodeBuilderInputText
            label="Question"
            placeholder="Enter a question"
            value={parsedContent.content}
            canEdit={canEditContent}
            error={error}
            setError={setError}
            handleOnChange={(newValue) =>
              handleTextInputOnChange({ newValue, question })
            }
            handleOnBlur={(newValue) =>
              updateQuestion(question.id, { content: newValue })
            }
            inputValidation={textInputValidation}
            disabled={questionLoading}
            autoFocus={true}
          />
        )
    }
  }

  const renderSettings = () => {
    return (
      <SettingsContainer>
        {question && !question.config.isTalkadotQuestion && (
          <CheckBoxStyled
            label="Make responses visible on the shareable report"
            checked={question.isAnswerPublic}
            disabled={questionLoading}
            onChange={() => {
              updateQuestion(question.id, {
                is_answer_public: !question.isAnswerPublic,
              })
            }}
          />
        )}
      </SettingsContainer>
    )
  }

  return (
    <>
      {renderInput()}
      {renderSettings()}
    </>
  )
}

export default CodeBuilderSettingsQuestion
