import { useState, useEffect } from 'react'
import { styled } from '@mui/system'
import {
  FormControl,
  Select,
  MenuItem,
  InputBase,
  Typography,
} from '@mui/material'

import { ReactComponent as CaretDownIcon } from '../../../icons/caretDown_16x16.svg'

import CodeBuilderInputWrapper from './CodeBuilderInputWrapper'

const SelectWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  gap: theme.spacing(0.5),
  fontFamily: 'Figtree, sans-serif',
  fontWeight: '400',
  fontSize: '0.875rem',
  paddingLeft: '10px',
  p: {
    color: theme.palette.neutral.main,
  },
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  flex: 1,
  height: '20px',
  padding: '1px 10px 1px 0',
  justifyContent: 'center',
}))

const StyledInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    width: '100%',
    fontFamily: 'Figtree, sans-serif',
    fontWeight: '400',
    fontSize: '0.875rem',
    border: 'none',
    position: 'relative',
    backgroundColor: 'transparent',
    color: theme.palette.neutral.main,
    textOverflow: 'ellipsis',
    padding: 0,
    '&:focus': {
      background: 'none',
    },
    '&::placeholder': {
      color: theme.palette.neutral.light,
      opacity: '50%',
    },
  },
}))

const StyledCaretIcon = styled(CaretDownIcon)(({ theme }) => ({
  path: {
    stroke: theme.palette.neutral.dark,
  },
}))

const CodeBuilderInputSelect = ({
  value,
  options,
  placeholder,
  handleOnChange,
  disabled,
  parsedEmojiContent,
}) => {
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    setIsFocused(true)
  }, [parsedEmojiContent?.id])

  const handleOnFocusCapture = (e) => {
    setIsFocused(true)
  }

  const handleClose = () => {
    // add delay to handle focus event that triggers after closing
    setTimeout(() => {
      setIsFocused(false)
    }, 100)
  }

  return (
    <CodeBuilderInputWrapper canEdit={true} isFocused={isFocused}>
      <SelectWrapper>
        <Typography>{parsedEmojiContent.content}</Typography>
        <StyledFormControl variant="standard">
          <Select
            value={value}
            onChange={(e) => handleOnChange(e.target.value)}
            onFocusCapture={(e) => handleOnFocusCapture(e)}
            onClose={handleClose}
            disabled={disabled}
            placeholder={placeholder}
            IconComponent={StyledCaretIcon}
            input={<StyledInput />}>
            {options.map((option) => (
              <MenuItem key={option.id} value={option.keyword}>
                {option.keyword}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </SelectWrapper>
    </CodeBuilderInputWrapper>
  )
}

export default CodeBuilderInputSelect
