import { styled } from '@mui/system'
import { Tooltip, Button } from '@mui/material'

import {
  ActionsTableCell,
  SessionFromEventIcon,
  TableCellContent,
  TableCellPrimaryContent,
  TableCellSecondaryContent,
} from '../common/TableComponents'
import TableActionsDropdown from '../common/TableActionsDropdown'
import CheckBoxStyled from '../common/CheckBoxStyled'
import TableChip from '../common/TableChip'
import TableCellStyled from '../common/TableCellStyled'
import { ReactComponent as LockIcon } from '../../icons/lockIcon_16x16.svg'

import { formatDate } from '../common/helpers'
import { renderCurrency, renderPercentage } from '../../utils/number'

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  fontSize: '0.75rem',
  lineHeight: 'normal',
  borderRadius: theme.shape.borderRadius.xs,
  borderColor: theme.palette.neutral.veryLight,
}))

const TalksTableRowSpeaker = ({
  event,
  isEventOwner,
  handleSelectEvent,
  menuItems,
  goToEventReport,
  handleOpenEventForm,
}) => {
  const handleCellClick = (value) => {
    // if cell does not contain a value AND user is event owner return,
    // let button handle click, otherwise open event report
    if (
      (value === '' || value === null || value === undefined) &&
      isEventOwner
    ) {
      return
    }
    goToEventReport(event)
  }

  // Locked codes should override the default eventCode value
  const eventCodeToDisplay = event.lockedOfferCode
    ? event.lockedOfferCode
    : event.eventCode

  const renderCellContent = ({ value, editButtonText = 'Add' }) => {
    if (value) {
      return <>{value}</>
    }

    return (
      <>
        {isEventOwner ? (
          <div>
            <StyledButton
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => handleOpenEventForm(event)}>
              {editButtonText}
            </StyledButton>
          </div>
        ) : (
          <>---</>
        )}
      </>
    )
  }

  return (
    <>
      <TableCellStyled
        padding="9px 0 9px 4px"
        width="46px"
        // displayBreakpoint="sm"
      >
        {!isEventOwner ? (
          <Tooltip
            placement="top"
            title="This session is from a talk you gave at an event that was organized by someone else.">
            <SessionFromEventIcon>E</SessionFromEventIcon>
          </Tooltip>
        ) : (
          <CheckBoxStyled
            checked={!!event.isSelected}
            onChange={handleSelectEvent}
            inputProps={{ 'aria-labelledby': event.id }}
          />
        )}
      </TableCellStyled>
      <TableCellStyled
        onClick={() => handleCellClick(event.date)}
        width="115px"
        textWrap="nowrap">
        {renderCellContent({ value: formatDate(event.date, event.utcOffset) })}
        {isEventOwner && event.unviewedSubmissionCount > 0 && (
          <TableCellSecondaryContent>
            <TableChip text={'New!'} color={'green'} />
          </TableCellSecondaryContent>
        )}
      </TableCellStyled>
      <TableCellStyled
        onClick={() => handleCellClick(event.eventOrganization)}
        width="20%"
        padding={event.eventOrganization ? 'normal' : 'none'}
        displayBreakpoint="md">
        <TableCellPrimaryContent boldPrimary={true}>
          {renderCellContent({ value: event.eventOrganization })}
        </TableCellPrimaryContent>
        {event.eventName && (
          <TableCellSecondaryContent>
            {event.eventName}
          </TableCellSecondaryContent>
        )}
      </TableCellStyled>
      <TableCellStyled
        onClick={() => handleCellClick(event.talkTitle)}
        minWidth="160px"
        fontWeight={event.talkTitle && '600'}
        padding={event.talkTitle ? 'normal' : 'none'}>
        <TableCellContent>
          {renderCellContent({
            value: event.talkTitle,
            editButtonText: 'Add Talk Title',
          })}
          {eventCodeToDisplay && (
            <Tooltip
              placement="top-start"
              disableInteractive
              title="This is the code used for audience feedback for this talk.">
              <TableCellSecondaryContent>
                <TableChip
                  text={eventCodeToDisplay.toUpperCase()}
                  color="grey"
                  icon={event.lockedOfferCode && <LockIcon />}
                />
              </TableCellSecondaryContent>
            </Tooltip>
          )}
        </TableCellContent>
      </TableCellStyled>

      <TableCellStyled
        align={event.compensation !== null ? 'right' : 'center'}
        padding={event.compensation ? 'normal' : 'none'}
        width="5%"
        displayBreakpoint="lg"
        onClick={() => handleCellClick(event.compensation)}>
        {renderCellContent({
          value: renderCurrency(event.compensation),
        })}
      </TableCellStyled>
      <TableCellStyled
        align="center"
        onClick={goToEventReport}
        width="5%"
        displayBreakpoint="lg">
        {renderPercentage(parseInt(event.rating))}
      </TableCellStyled>
      <TableCellStyled
        align="center"
        onClick={goToEventReport}
        width="5%"
        displayBreakpoint="sm">
        {event.responses}
      </TableCellStyled>
      <TableCellStyled
        align="center"
        onClick={goToEventReport}
        width="5%"
        displayBreakpoint="sm">
        {event.leads}
      </TableCellStyled>
      <ActionsTableCell align="center">
        <TableActionsDropdown menuItems={menuItems} />
      </ActionsTableCell>
    </>
  )
}

export default TalksTableRowSpeaker
