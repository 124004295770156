import React, { createContext, useReducer } from 'react'
import {
  authenticationReducer,
  initialAuthState,
} from './authenticationReducer'
import history from '../../routes/history'
import Api from '../../services/api'
import * as Cookies from '../../services/cookies'
import * as ActionTypes from './actionTypes'
import * as Messages from '../common/AlertMessages'
import isEqual from 'lodash/isEqual'
import { humanizeError } from '../../utils/errorFormatter'

export const AuthenticationContext = createContext({
  authData: initialAuthState,
})

const AuthenticationContextProvider = (props) => {
  const [authData, dispatch] = useReducer(
    authenticationReducer,
    initialAuthState
  )
  const { membership } = authData?.user

  const setUserData = (data) => {
    dispatch({
      type: ActionTypes.SET_AUTH_DATA,
      payload: data,
    })
  }

  const setGroupInvitations = (groupInvitationData) => {
    dispatch({
      type: ActionTypes.SET_GROUP_INVITATIONS,
      payload: groupInvitationData,
    })
  }

  const removeGroupInvitation = (groupInvitationData) => {
    dispatch({
      type: ActionTypes.REMOVE_GROUP_INVITATION,
      payload: groupInvitationData,
    })
  }

  const setUserMembership = (membership) => {
    dispatch({
      type: ActionTypes.SET_USER_MEMBERSHIP,
      payload: membership,
    })
  }

  const logoutUser = () => {
    Cookies.deleteAuthToken()
    Cookies.deleteImpersonationToken()
    dispatch({
      type: ActionTypes.RESET_AUTH_DATA,
    })
    return history.push('/login')
  }

  const setProfileAttribute = (attributeType, value) => {
    dispatch({
      type: ActionTypes.SET_USER_ATTRIBUTE,
      payload: {
        attributeType: attributeType,
        value: value,
      },
    })
  }

  const setSocialAttribute = (attributeType, value) => {
    dispatch({
      type: ActionTypes.SET_SOCIAL_MEDIA_LINK,
      payload: {
        attributeType: attributeType,
        value: value,
      },
    })
  }

  const submitBookingLink = async () => {
    if (!canShowBookingLink) {
      setProfileAttribute('booking_link', '')
      return setNotification(
        'You need to upgrade to a paid plan to add a booking link to your survey!'
      )
    }

    const params = {
      user: {
        id: authData.user.id,
        booking_link: authData.user.booking_link,
      },
    }

    try {
      const res = await Api.updateUser(params)

      if (!res.errors) {
        setProfileAttribute('booking_link', authData.user.booking_link)
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err)
    }
  }

  const submitSpeakerflowLink = async (isIntegrationsAllowed) => {
    if (!isIntegrationsAllowed) {
      setProfileAttribute('speakerflow_url', '')
      return setNotification(
        'You need to upgrade to a paid plan to add a speakerflow link to your account'
      )
    }

    const params = {
      integration: {
        integration_type: 'SPEAKERFLOW',
        integration_url: authData.user.speakerflow_url,
      },
    }

    try {
      const res = await Api.createIntegration(params)

      if (!res.errors) {
        setProfileAttribute('speakerflow_url', authData.user.speakerflow_url)
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err)
    }
  }

  const toggleSpeakerflowEnabled = async (enabled, isIntegrationsAllowed) => {
    if (!isIntegrationsAllowed) {
      return setNotification(
        'You need to upgrade to a paid plan to add a speakerflow link to your account'
      )
    }

    const params = {
      integration: {
        integration_type: 'SPEAKERFLOW',
        enabled: enabled,
      },
    }

    try {
      const res = await Api.updateIntegration(params, null)

      if (!res.errors) {
        setProfileAttribute('speakerflowEnabled', enabled)
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err)
    }
  }

  const submitToggleShowTalkadotBranding = async (showBranding) => {
    const params = {
      user: {
        id: authData.user.id,
        show_talkadot_branding: showBranding,
      },
    }

    try {
      const res = await Api.updateUser(params)

      if (!res.errors) {
        setProfileAttribute('showTalkadotBranding', showBranding)
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err)
    }
  }

  const submitToggleAutoSplitEvents = async (autoSplitEvents) => {
    const params = {
      user: {
        id: authData.user.id,
        autosplit_events: autoSplitEvents,
      },
    }

    try {
      const res = await Api.updateUser(params)

      if (!res.errors) {
        setProfileAttribute('autoSplitEvents', autoSplitEvents)
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err)
    }
  }

  const submitShowUpgradeBanner = async (showUpgradeBanner) => {
    const params = {
      user: {
        id: authData.user.id,
        show_upgrade_banner: showUpgradeBanner,
      },
    }

    try {
      const res = await Api.updateUser(params)

      if (!res.errors) {
        setProfileAttribute('showUpgradeBanner', showUpgradeBanner)
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err)
    }
  }

  const handleAuthenticationSuccess = (res, redirect = true) => {
    Cookies.saveAuthToken(res.auth_token)
    setUserData(res.user)
    updateCounter({
      unviewedCommunityNotifications: 1,
    })
    if (redirect) {
      return redirectUser(res.user)
    }
  }

  const resetPassword = async () => {
    const params = {
      user: {
        current_password: authData.user.currentPassword,
        new_password: authData.user.newPassword,
      },
    }

    try {
      const res = await Api.resetPassword(params)

      if (!res.errors) {
        setProfileAttribute('newPassword', '')
        setProfileAttribute('currentPassword', '')
        return setNotification('Password Updated!', 'success')
      } else {
        throw res.errors
      }
    } catch (err) {
      return setNotification(err, 'error')
    }
  }

  const redirectUser = (user) => {
    if (user.onboarding_complete) {
      if (user.isEventPlanner) {
        return history.push('/event-planner/dashboard')
      }

      history.push('/dashboard')
    } else {
      history.push('/welcome')
    }
  }

  const authenticateUserByGoogle = async (googleData, redirect) => {
    try {
      showFullPageLoader(Messages.AUTH_MESSAGE)
      const res = await Api.googleAuth({ token: googleData.tokenId })

      if (!res.errors && res.auth_token) {
        hideFullPageLoader()
        return handleAuthenticationSuccess(res, redirect)
      } else {
        throw res.errors
      }
    } catch (err) {
      hideFullPageLoader()
      return setNotification(err, 'error')
    }
  }

  const authenticateUserByLinkedin = async (linkedinCode, redirect) => {
    try {
      showFullPageLoader(Messages.AUTH_MESSAGE)
      const res = await Api.linkedinAuth({ code: linkedinCode })

      if (!res.errors && res.auth_token) {
        hideFullPageLoader()
        return handleAuthenticationSuccess(res, redirect)
      } else {
        throw res.errors
      }
    } catch (err) {
      hideFullPageLoader()
      return setNotification(err)
    }
  }

  const registerUserByGoogle = async (
    googleData,
    planToken,
    registrationToken
  ) => {
    try {
      showFullPageLoader(Messages.AUTH_MESSAGE)

      const params = {
        token: googleData.tokenId,
        plan_token: planToken,
        registration_token: registrationToken,
      }

      const res = await Api.registerWithGoogle(params)

      if (!res.errors && res.auth_token) {
        hideFullPageLoader()
        return handleAuthenticationSuccess(res)
      } else {
        throw res.errors
      }
    } catch (err) {
      hideFullPageLoader()
      return setNotification(err, 'error')
    }
  }

  const registerUserByLinkedin = async (
    linkedinCode,
    planToken,
    registrationToken
  ) => {
    try {
      showFullPageLoader(Messages.AUTH_MESSAGE)

      const params = {
        code: linkedinCode,
        plan_token: planToken,
        registration_token: registrationToken,
      }

      const res = await Api.registerWithLinkedin(params)

      if (!res.errors && res.auth_token) {
        hideFullPageLoader()
        return handleAuthenticationSuccess(res)
      } else {
        throw res.errors
      }
    } catch (err) {
      hideFullPageLoader()
      return setNotification(err, 'error')
    }
  }

  const toggleTos = () => {
    dispatch({
      type: ActionTypes.TOGGLE_TOS,
    })
  }

  const togglePrivacy = () => {
    dispatch({
      type: ActionTypes.TOGGLE_PRIVACY,
    })
  }

  const showFullPageLoader = (loaderContent, loaderType) => {
    dispatch({
      type: ActionTypes.SHOW_FULL_PAGE_LOADER,
      payload: {
        content: loaderContent,
        loaderType: loaderType,
      },
    })
  }

  const closeNotification = () => {
    dispatch({
      type: ActionTypes.SET_NOTIFICATION,
      payload: {
        message: '',
        notificationType: 'info',
        showNotification: false,
      },
    })
  }

  const setNotification = (message, notificationType = 'error') => {
    dispatch({
      type: ActionTypes.SET_NOTIFICATION,
      payload: {
        message: humanizeError(message),
        notificationType: notificationType,
        showNotification: true,
      },
    })
  }

  const hideFullPageLoader = () => {
    dispatch({
      type: ActionTypes.HIDE_FULL_PAGE_LOADER,
    })
  }

  // Open the upgrade modal
  // NOTE: Only send permissionType arg if you want to filter out plans that DO NOT HAVE this permission
  const toggleUpgradeModal = (
    shouldShowUpgradeModal,
    helperText,
    permissionType
  ) => {
    dispatch({
      type: ActionTypes.TOGGLE_UPGRADE_MODAL,
      payload: {
        showModal: shouldShowUpgradeModal,
        helperText: helperText,
        permissionType: permissionType,
      },
    })
  }

  const updatePermission = (updatedPermission) => {
    const existing = membership.permissions.find(
      (permission) => permission.type === updatedPermission.type
    )

    // to limit unnecessary re-renders only update if the permission has changed
    if (existing && isEqual(existing, updatedPermission)) {
      return
    }

    dispatch({
      type: ActionTypes.SET_PERMISSION,
      payload: updatedPermission,
    })
  }

  const updateCounter = (counterData) => {
    dispatch({
      type: ActionTypes.UPDATE_COUNTER,
      payload: counterData,
    })
  }

  const showTalkadotBranding = () => {
    return authData?.user?.showTalkadotBranding
  }

  // TODO move these to permission_helper once this become a permission
  const canShowBookingLink = membership?.plan?.price > 0

  return (
    <AuthenticationContext.Provider
      value={{
        authData,
        setUserData,
        logoutUser,
        redirectUser,
        setProfileAttribute,
        setSocialAttribute,
        authenticateUserByGoogle,
        registerUserByGoogle,
        authenticateUserByLinkedin,
        registerUserByLinkedin,
        toggleTos,
        togglePrivacy,
        showFullPageLoader,
        hideFullPageLoader,
        setNotification,
        closeNotification,
        resetPassword,
        toggleUpgradeModal,
        showTalkadotBranding,
        submitToggleShowTalkadotBranding,
        submitShowUpgradeBanner,
        submitToggleAutoSplitEvents,
        handleAuthenticationSuccess,
        submitBookingLink,
        submitSpeakerflowLink,
        canShowBookingLink,
        toggleSpeakerflowEnabled,
        setGroupInvitations,
        removeGroupInvitation,
        setUserMembership,
        updatePermission,
        updateCounter,
      }}>
      {props.children}
    </AuthenticationContext.Provider>
  )
}

export default AuthenticationContextProvider
