import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import YouTube from 'react-youtube'
import { styled } from '@mui/system'
import {
  Dialog,
  DialogContent,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import { ReactComponent as PlusIcon } from '../../icons/plusIcon.svg'
import { ReactComponent as CodesIcon } from '../../icons/codesIcon_16x16.svg'
import { ReactComponent as MostUsedCodeIcon } from '../../icons/sunRiseIcon_36x36.svg'

import { AuthenticationContext } from '../authentication/authenticationContext'
import Api from '../../services/api'

import { processWithFallback } from '../common/helpers'

import { initialOfferState } from '../../constants/codes/initialOfferStates'
import SurveyOffersForm from '../dashboard/SurveyOffersForm'
import SurveyOffersSlide from '../dashboard/SurveyOffersSlide'
import CodesTable from './CodesTable'
import PageContainer from '../common/PageContainer'
import PageHeader from '../common/PageHeader'
import StatsSummaryHeader from '../common/StatsSummaryHeader'
import TableActions from '../common/TableActions'
import DownloadableQrCode from '../common/DownloadableQrCode'
import { Notification } from '../common/Notification'

import {
  usePermissionHelper,
  OFFER_CODE_LIMIT,
} from '../../utils/permission_helper'
import { Typography } from '@material-ui/core'

const VideoDialogContent = styled(DialogContent)({
  minHeight: '600px',
  padding: 0,
})

const VideoContainer = styled('div')({
  height: '600px',
})

const CustomCodesParent = () => {
  const [offers, setOffers] = useState([])
  const [offer, setOffer] = useState(initialOfferState)
  const [surveyLink, setSurveyLink] = useState('')
  const [mostUsedCode, setMostUsedCode] = useState('')
  const [loading, setLoading] = useState(true)
  const [surveyOfferOpen, setSurveyOfferOpen] = useState(false)
  const [slideDownloadOpen, setSlideDownloadOpen] = useState(false)
  const [defaultEmojiKeywords, setDefaultEmojiKeywords] = useState([])
  const [surveyOffersFormModalSize, setSurveyOffersFormModalSize] =
    useState('md')
  const [surveyOffersFormModalFullWidth, setSurveyOffersFormModalFullWidth] =
    useState(true)
  const [videoModalOpen, setVideoModalOpen] = useState(false)
  const [videoId, setVideoId] = useState(null)
  const [qrDownloadOpen, setQrDownloadOpen] = useState(false)
  const history = useHistory()

  const { authData, setNotification, toggleUpgradeModal } = useContext(
    AuthenticationContext
  )
  const { user } = authData
  const {
    membership: { isTrialing },
  } = user

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { canCreateSurveyOffer, findPermission } = usePermissionHelper()

  const defaultCode = {
    name: 'Use this code to skip giving an offer',
    code: 'TALK',
    createdAt: null,
  }

  useEffect(() => {
    const fetchCodes = async () => {
      try {
        const res = await Api.getCodes()

        if (!res.errors) {
          setLoading(false)
          setOffers([defaultCode, ...res])
          // setSurveyLink(res.shortlink)
          // setMostUsedCode(res.mostUsedCode?.toUpperCase())
        } else {
          throw res.errors
        }
      } catch (error) {
        setLoading(false)
        setNotification(error, 'error')
      }
    }

    const getDefaultEmojiKeywords = async () => {
      try {
        const res = await Api.fetchDefaultEmojiKeywords()

        if (!res.errors) {
          setDefaultEmojiKeywords(res)
        }
      } catch (err) {}
    }

    fetchCodes()
    getDefaultEmojiKeywords()
  }, [])

  const handleClose = () => {
    setOffer(initialOfferState)
    setSurveyOfferOpen(false)
  }

  const handleEdit = (offer) => {
    history.push(`/codes/edit/${offer.id}`)
  }

  const handleOpenCreateOfferForm = () => {
    if (canCreateSurveyOffer(offers?.length - 1)) {
      return setSurveyOfferOpen(true)
    } else {
      return toggleUpgradeModal(
        true,
        "Looks like you've reached your offer limit =(.  Upgrade now for higher limits and more features!",
        OFFER_CODE_LIMIT
      )
    }
  }

  const handleOpenSlideDownload = (offer) => {
    setOffer(offer)
    setSlideDownloadOpen(true)
  }

  const handleCloseSlideDownload = () => {
    setSlideDownloadOpen(false)
    // use setTimeout to allow the modal to close before resetting the offer
    setTimeout(() => {
      setOffer(initialOfferState)
    }, 500)
  }

  const handleDownloadQrCode = (offer) => {
    setOffer(offer)
    setQrDownloadOpen(true)
  }

  const handleCloseQrDownload = () => {
    setQrDownloadOpen(false)
    setTimeout(() => {
      setOffer(initialOfferState)
    }, 500)
  }

  const handleVideoModalClose = () => {
    setVideoModalOpen(false)
    setVideoId('')
  }

  const videoOpts = {
    playerVars: {
      autoplay: 1,
    },
    width: '100%',
    height: '100%',
  }

  const { limit, unlimitedUsage } = findPermission(OFFER_CODE_LIMIT)

  const codesConsumed = loading ? 0 : offers.length - 1

  const statsSummaryItems = [
    {
      label: 'Total Codes',
      value: processWithFallback(codesConsumed, (val) =>
        unlimitedUsage ? val : `${val}/${limit}`
      ),
      icon: <CodesIcon />,
      svgType: 'fill',
      showUpgradeButton: !isTrialing && !unlimitedUsage,
      upgradeButtonText: 'Upgrade for more codes!',
    },
    {
      label: 'Most Used',
      value: mostUsedCode,
      icon: <MostUsedCodeIcon />,
      svgType: 'stroke',
    },
  ]

  return (
    <PageContainer>
      <PageHeader
        header="Your Talkadot Codes"
        showUpgradeButton={user?.membership?.shouldShowUpgrades}
      />
      {!loading && offers?.length < 2 && (
        <Notification hideClose={true} variant="greyWarning">
          <Typography>
            <b>Talkadot codes are used in order to:</b>
          </Typography>
          <Typography>1. Configure the questions your audience sees</Typography>
          <Typography>
            2. Set up any bonuses, offers or downloads for your audience
          </Typography>
        </Notification>
      )}
      <StatsSummaryHeader statItems={statsSummaryItems} />
      <TableActions
        ctaOpts={{
          onClick: handleOpenCreateOfferForm,
          text: 'Create a new code',
          icon: <PlusIcon />,
        }}
      />
      <CodesTable
        offers={offers}
        setOffers={setOffers}
        loading={loading}
        handleEdit={handleEdit}
        handleOpenSlideDownload={handleOpenSlideDownload}
        handleDownloadQrCode={handleDownloadQrCode}
        // TODO: remove this prop once the feature is implemented
        customCodes={true}
      />
      <Dialog
        open={surveyOfferOpen}
        fullScreen={fullScreen}
        fullWidth={surveyOffersFormModalFullWidth}
        maxWidth={surveyOffersFormModalSize}
        onClose={() => handleClose()}>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <SurveyOffersForm
          setSurveyOfferOpen={setSurveyOfferOpen}
          handleClose={handleClose}
          offers={offers}
          setOffers={setOffers}
          setOffer={setOffer}
          offer={offer}
          defaultEmojiKeywords={defaultEmojiKeywords}
          setVideoId={setVideoId}
          setVideoModalOpen={setVideoModalOpen}
          setSurveyOffersFormModalSize={setSurveyOffersFormModalSize}
          setSurveyOffersFormModalFullWidth={setSurveyOffersFormModalFullWidth}
          surveyLink={surveyLink}
        />
      </Dialog>
      <Dialog
        open={slideDownloadOpen}
        fullwidth="true"
        maxWidth="xl"
        sx={{ minWidth: '960px' }}
        onClose={handleCloseSlideDownload}>
        <CloseIcon
          className="modal-close-icon"
          sx={{ fill: 'white' }}
          onClick={handleCloseSlideDownload}
        />
        <SurveyOffersSlide code={offer.code} />
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        open={videoModalOpen}
        onClose={handleVideoModalClose}>
        <VideoDialogContent>
          <VideoContainer>
            <YouTube
              videoId={videoId}
              opts={videoOpts}
              style={{ width: '100%', height: '100%' }}
            />
          </VideoContainer>
        </VideoDialogContent>
      </Dialog>
      <Dialog
        open={qrDownloadOpen}
        fullwidth="true"
        maxWidth="xl"
        onClose={handleCloseQrDownload}>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={handleCloseQrDownload}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent style={{ padding: '30px' }}>
          <DownloadableQrCode code={offer.code} surveyLink={surveyLink} />
        </DialogContent>
      </Dialog>
    </PageContainer>
  )
}

export default CustomCodesParent
