import { styled } from '@mui/system'
import { Typography, Button } from '@mui/material'

import { ReactComponent as PlusIcon } from '../../../icons/plusIcon_16x16.svg'

import CodeBuilderToggleQuestionButton from './CodeBuilderToggleQuestionButton'
import CodeBuilderQuestionTypePicker from './CodeBuilderQuestionTypePicker'

const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(0.75),
  minHeight: theme.spacing(5.5),
  background: theme.palette.surface.subtle,
  borderTopLeftRadius: theme.shape.borderRadius.xxs,
  borderTopRightRadius: theme.shape.borderRadius.xxs,
}))

const QuestionType = styled(Typography)(({ theme }) => ({
  flex: 1,
  color: theme.palette.text.hint,
  fontWeight: theme.fontWeights.semiBold,
  span: {
    color: theme.palette.text.link,
  },
  svg: {
    path: {
      stroke: theme.palette.neutral.darkest,
    },
  },
}))

const AddQuestionButton = styled(Button)(({ theme }) => ({
  height: '32px',
  backgroundColor: theme.palette.base.white,
  borderRadius: theme.shape.borderRadius.xs,
  color: theme.palette.neutral.darkest,
  marginRight: theme.spacing(3.25),
  svg: {
    path: {
      stroke: theme.palette.neutral.darkest,
    },
  },
}))

const CodeBuilderQuestionHeader = ({
  question,
  canAddQuestions = false,
  updateQuestion,
  changeQuestionType,
  handleAddQuestion,
  questionLoading,
  availableQuestionTypes,
}) => {
  const updateQuestionEnabled = (questionId) => {
    updateQuestion(questionId, { enabled: !question.enabled })
  }

  // Perhaps add more UX validations here if needed
  const canChangeQuestionType =
    !question.config?.isTalkadotQuestion && question.questionType !== 'code'

  const showQuestionType =
    question.questionType !== 'code' &&
    question.questionType !== 'downloadSlide'

  const renderHeaderContent = () => {
    return (
      <>
        <QuestionType variant="body1">
          {showQuestionType && 'Question Type: '}
          <span>{question.config?.questionTypeDescription}</span>
          {/* don't render the picker if there are no available question types */}
          {canChangeQuestionType && availableQuestionTypes?.length > 0 && (
            <CodeBuilderQuestionTypePicker
              availableQuestionTypes={availableQuestionTypes}
              changeQuestionType={changeQuestionType}
              question={question}
            />
          )}
        </QuestionType>
        {canAddQuestions && (
          <AddQuestionButton
            variant="outlined"
            startIcon={<PlusIcon />}
            color="tertiary"
            disabled={questionLoading}
            onClick={() => handleAddQuestion(question.id)}>
            Add a question
          </AddQuestionButton>
        )}
        {question.config?.canToggleEnable && (
          <CodeBuilderToggleQuestionButton
            handleToggleQuestion={() => updateQuestionEnabled(question.id)}
            enabled={question.enabled}
          />
        )}
      </>
    )
  }

  return <HeaderContainer>{renderHeaderContent()}</HeaderContainer>
}

export default CodeBuilderQuestionHeader
