import { styled } from '@mui/system'
import { useTheme, Typography, Tooltip } from '@mui/material'

// import { ReactComponent as DragIcon } from '../../../icons/burgerIcon_16x16.svg'
import { ReactComponent as CustomSettingsIcon } from '../../../icons/customSettingsIcon_16x16.svg'

import CodeBuilderToggleQuestionButton from './CodeBuilderToggleQuestionButton'

const QuestionItemContainer = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'selected' && prop !== 'isGroupChild' && prop !== 'disabled',
})(({ theme, selected, isGroupChild, disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: theme.spacing(5.5),
  padding: theme.spacing(0, 1.75),
  marginLeft: isGroupChild ? theme.spacing(4) : 0,
  backgroundColor: selected
    ? theme.palette.neutral.main
    : theme.palette.base.white,
  borderRadius: theme.shape.borderRadius.xxs,
  border: `1px solid ${theme.palette.border.light}`,
  // cursor: 'pointer',
  cursor: disabled ? 'not-allowed' : 'pointer',
}))

// const DragHandleContainer = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   paddingRight: theme.spacing(1.75),
//   minWidth: '28px',
// }))

// const StyledDragIcon = styled(DragIcon)(({ theme }) => ({
//   cursor: 'grab',
//   path: {
//     fill: theme.palette.text.disabled,
//   },
// }))

const StyledCustomizedIcon = styled(CustomSettingsIcon)(({ theme }) => ({
  path: {
    stroke: theme.palette.text.disabled,
  },
}))

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  color: selected ? theme.palette.base.white : theme.palette.text.disabled,
  fontWeight: theme.fontWeights.semiBold,
  paddingLeft: theme.spacing(1.75),
  flex: 1,
}))

const CodeBuilderPreviewQuestionItem = ({
  question,
  selected,
  sortable = false,
  canToggleEnable = false,
  disabled = false,
  setCurrentQuestionId,
  questionNumber,
  updateQuestion,
  isGroupChild,
  tooltipText,
}) => {
  const theme = useTheme()

  const handleClick = () => {
    if (disabled || selected) {
      return
    }

    setCurrentQuestionId(question.id)
  }

  const updateQuestionEnabled = (questionId) => {
    updateQuestion(questionId, { enabled: !question.enabled })
  }

  const renderPreviewContent = () => {
    const content = `${questionNumber}. ${question.config?.previewName}`

    if (
      question.questionType === 'EMOJI_SCALE' &&
      question.config.isTalkadotQuestion
    ) {
      return `${content}: ${question.replacementValue}`
    }

    // For custom questions, show a truncated version of the
    // question content in the preview
    if (!question.config.isTalkadotQuestion && question.content) {
      return `${content} ${question.content?.substring(0, 10) + '...'}`
    }

    return content
  }

  return (
    <Tooltip title={tooltipText} placement="left">
      <QuestionItemContainer
        selected={selected}
        onClick={handleClick}
        isGroupChild={isGroupChild}
        disabled={disabled}>
        {/* TODO: enable drag handle once we implement this functionality */}
        {/* <DragHandleContainer selected={selected}>
        {sortable && <StyledDragIcon />}
      </DragHandleContainer> */}
        <StyledTypography variant="body1" selected={selected}>
          {renderPreviewContent()}
        </StyledTypography>
        {question.hasBeenCustomized && (
          <Tooltip title="This question has been customized from the default settings">
            <StyledCustomizedIcon />
          </Tooltip>
        )}
        {canToggleEnable && (
          <CodeBuilderToggleQuestionButton
            handleToggleQuestion={() => updateQuestionEnabled(question.id)}
            enabled={question.enabled}
            iconColor={theme.palette.text.disabled}
          />
        )}
      </QuestionItemContainer>
    </Tooltip>
  )
}

export default CodeBuilderPreviewQuestionItem
